// automatic written by ruby script .. please dont edit these comments!
// storyblok attributes:
//   venue -> option
// end of automatic

import React, { Fragment } from "react"
import Link from "gatsby-theme-yum-components/src/components/atoms/Link"
import Layout from "gatsby-theme-yum-components/src/components/modules/Layout"
import SEO from "gatsby-theme-yum-components/src/components/modules/Seo"
import menuItems from "../../components/constants/menuItems"
import * as moment from "moment"

import _ from "lodash"

import BlockWrapper from "gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import Headline from "gatsby-theme-yum-components/src/components/atoms/Headline"
import { Helmet } from "react-helmet"
import ThemeLogo from "../../components/modules/ThemeLogo"
import Image from "gatsby-theme-yum-components/src/components/atoms/Image"

const EventArchivePage = ({ venue, location, events }) => {
  const [themeLogo] = ThemeLogo(venue)

  const content = { headline: "Archiv" }

  const eventsByMonth = _.groupBy(
    _.sortBy(events, "date.datetime", "desc").reverse(),
    (item) => moment(item.date.datetime).format("YYYY")
  )

  let submenuItems = {}
  submenuItems.headline = "Archiv"
  submenuItems.links = Object.keys(eventsByMonth).map((key) => ({
    title: key,
    url: "#" + key.replace(" ", "-"),
    type: "anchor",
  }))
  submenuItems.cta_title = "Veranstaltungskalender"
  submenuItems.cta_link = "/" + venue + "/schedule"

  return (
    <Layout
      submenuItems={submenuItems}
      menuItems={menuItems}
      venue={venue}
      location={location}
    >
      <SEO title={content.headline}></SEO>
      <Helmet
        bodyAttributes={{
          class: `theme-${venue} font-body antialiased`,
        }}
      />

      <div
        className={`pb-8 ${
          content.headline?.length > 0 ? "lg:pb-0" : "lg:pb-32"
        }`}
      >
        <div className="absolute top-0 right-0 z-50 w-1/2 h-auto px-8 pt-4 ml-auto opacity-100 lg:w-72">
          {themeLogo && (
            <div className="relative w-full pb-24 ml-auto sm:w-48 lg:w-56 lg:pb-32">
              <div className="absolute top-0 right-0 w-auto h-full">
                <Image
                  image={themeLogo}
                  className={`object-contain object-right-top h-full w-full`}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      {content.headline?.length > 0 && (
        <BlockWrapper block={{}} blockPadding="pb-0 pt-16 lg:pb-16 lg:pt-10">
          <h1 className="text-3xl font-bold font-display">
            {content.headline}
          </h1>
        </BlockWrapper>
      )}
      <BlockWrapper className="-mt-16" block={{}} blockWidth="container">
        {Object.keys(eventsByMonth)
          .reverse()
          .map((month, index) => (
            <Fragment key={index}>
              <div
                className="flex flex-wrap mb-4 border-b-2 flew-row"
                id={month.replace(" ", "-")}
              >
                <div className="w-full mt-8 mb-4">
                  <Headline headline={month} headline_level="h4" />
                </div>
                <span className="w-1/4 pr-4 xl:w-1/6">
                  <b>Datum</b>
                </span>
                <span className="w-1/4 pr-4 xl:w-1/6">
                  <b>Uhrzeit</b>
                </span>
                <span className="w-2/4 pr-4 xl:w-4/6">
                  <b>Event</b>
                </span>
              </div>
              {eventsByMonth[month].map((item, index) => (
                <div
                  className="flex flex-wrap mb-4 text-sm md:text-base flew-row"
                  key={index}
                >
                  <span className="w-1/4 pr-4 xl:w-1/6">
                    {moment(item.date.datetime).format("dd. DD.MM.")}
                  </span>
                  <span className="w-1/4 pr-4 xl:w-1/6">
                    {moment(item.date.datetime).format("HH:mm")}&nbsp;Uhr
                  </span>
                  <span className="w-2/4 pr-4 xl:w-4/6">
                    <Link
                      className="block mr-2 text-primary hover:text-primary-hover"
                      link={item.url}
                    >
                      {item.headline}
                    </Link>
                  </span>
                </div>
              ))}
            </Fragment>
          ))}
      </BlockWrapper>
    </Layout>
  )
}

export default EventArchivePage
